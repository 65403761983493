import {Button, Dropdown, Form, Grid, Header, Icon, Input, Loader, Menu, Table} from "semantic-ui-react";
import {safeSpaceOptions} from "../../../constants/SelectableListOptions";
import ConfirmDeleteModal from "../../MyForms/ConfirmDeleteModal";
import { getFunctions, httpsCallable } from "firebase/functions";
import { updatePaginate } from "../../../actions/paginateTypes";
import {connect, useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {db} from "../../../firebase";
import {DateTime} from "luxon";
import _, {debounce} from "lodash";
import {mapSafeSpaceOptionsToString} from "../../../utilities/helpers";

const AllForms = ({isAdmin = false, overwriteTeamLeader = ''}) => {
    const [teamLeaderOptions, setTeamLeaderOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [dateRange, setDateRange] = useState(() => {
        const endDate = new Date();

        const startDate = new Date(new Date().setDate(endDate.getDate() - 30));
        startDate.setHours(0, 0, 0, 0);

        const formatISODate = (date) =>
            new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                .toISOString()
                .split('T')[0];


        return {
            startDate: formatISODate(startDate),
            endDate: formatISODate(endDate),
        };
    });

    const [isValidDateRange, setIsValidDateRange] = useState(true);

    const [formState, setFormState] = useState({
        confirmDeleteModalOpen: false,
        confirmDeleteFormId: "",
        itemArray: [],
        totalCount: 0,
    });
    const dispatch = useDispatch();
    const { pageSize, pageIndex, formType, teamLeader, site, startDate, endDate } = useSelector(
        (state) => state.paginate
    );
    const functions = getFunctions();
    const getIncidentForms = httpsCallable(functions, 'httpsPaginationWithFilters');

    useEffect(() => {
        fetchTeamLeaders();
    }, []);

    const debouncedFetchIncidentForms = useCallback(debounce(() => {
        if (isValidDateRange) {
            fetchIncidentForms();
        }
    }, 500), [pageSize, pageIndex, formType, teamLeader, site, isValidDateRange, dateRange, refresh]);

    useEffect(() => {
        debouncedFetchIncidentForms();

        return () => {
            debouncedFetchIncidentForms.cancel();
        };
    }, [debouncedFetchIncidentForms]);

    const fetchIncidentForms = async () => {
        setLoading(true);
        try {
            const getEndOfDayTime = (date) => {
                const endOfDay = new Date(date);
                endOfDay.setHours(23, 59, 59, 999);
                return endOfDay.getTime();
            };
            const result = await getIncidentForms({
                pageSize: pageSize ?? 10,
                pageIndex: pageIndex,
                formType: formType ?? 'all',
                teamLeader: (overwriteTeamLeader ? overwriteTeamLeader : teamLeader ?? 'all'),
                startDate: new Date(startDate ?? dateRange.startDate).getTime(),
                endDate: getEndOfDayTime(endDate ?? dateRange.endDate),
                site: site ?? 'all'
            });

            const { incidentForms, totalCount } = result.data;

            setFormState((prevFormState) => ({
                ...prevFormState,
                itemArray: incidentForms,
                totalCount: totalCount,
            }));
        } catch (error) {
            console.error("Error fetching incident forms:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTeamLeaders = async () => {
        try {
            const usersRef = db.getRefOfUsersNode();
            const snapshot = await usersRef.orderByChild('userLevel').equalTo('teamLeader').once('value');
            const options = [];
            snapshot.forEach((childSnapshot) => {
                const teamLeader = childSnapshot.val();
                options.push({
                    key: childSnapshot.key,
                    text: teamLeader.firstName + " " + teamLeader.lastName,
                    value: teamLeader.firstName + " " + teamLeader.lastName,
                });
            });
            const sortedOptions = _.uniqBy(_.orderBy(options, 'text', 'asc'), function(e){
                return e.text;
            })
            setTeamLeaderOptions(sortedOptions);
        } catch (error) {
            console.error('Error fetching team leaders:', error);
        }
    };

    const handlePageSizeChange = (e, { value }) => {
        dispatch(updatePaginate({ pageSize: value, pageIndex: 1 }));
    };

    const handlePageIndexChange = (e, { value }) => {
        dispatch(updatePaginate({ pageIndex: parseInt(value) }));
    };

    const handleFormTypeChange = (e, { value }) => {
        dispatch(updatePaginate({ formType: value, pageIndex: 1 }));
    };

    const handleTeamLeaderChange = (e, { value }) => {
        dispatch(updatePaginate({ teamLeader: value, pageIndex: 1 }));
    };

    const handleSiteChange = (e, { value }) => {
        dispatch(updatePaginate({ site: value, pageIndex: 1 }));
    };

    const openConfirmDeleteModal = (incidentFormToDelete) => {
        setFormState((prevState) => ({
            ...prevState,
            confirmDeleteModalOpen: true,
            confirmDeleteFormId: incidentFormToDelete,
        }));
    };

    const closeModal = () => {
        setFormState((prevState) => ({
            ...prevState,
            confirmDeleteModalOpen: false,
            confirmDeleteFormId: "",
        }));
    };

    const generateDataRows = () => {
        if (formState.itemArray.length > 0) {
            return _.map(formState.itemArray, (item) => {
                return (
                    <Table.Row
                        key={item.itemId}
                        error={item.itemData.status === "draft"} // paint table row red if form is still draft
                    >
                        <Table.Cell>
                            {DateTime.fromMillis(item.itemData.startTime || 0).toFormat(
                                "ccc MMMM d, h:mm a"
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            {_.truncate(item.itemData.incidentDescription, {
                                length: 250,
                                omission: "...",
                            }) || "(No Incident Description)"}
                        </Table.Cell>
                        <Table.Cell>
                            {mapSafeSpaceOptionsToString[item.itemData.site]}
                        </Table.Cell>
                        <Table.Cell>
                            {item.itemData.clientList === undefined
                                ? 0
                                : item.itemData.clientList.length}
                        </Table.Cell>
                        {isAdmin && <Table.Cell>{item.itemData.teamLeaderName}</Table.Cell>}
                        <Table.Cell textAlign="center">
                            <Button.Group widths={3}>
                                <Button
                                    animated="vertical"
                                    color="blue"
                                    as={Link}
                                    to={{
                                        pathname: "/incident/view/",
                                        state: { incidentId: item.itemId },
                                    }}
                                >
                                    <Button.Content visible>View</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="eye" />
                                    </Button.Content>
                                </Button>
                                <Button
                                    animated="vertical"
                                    color="yellow"
                                    as={Link}
                                    to={{
                                        pathname: "/incident/edit/",
                                        state: { incidentId: item.itemId, editFrom: "/admin" },
                                    }}
                                >
                                    <Button.Content visible>Edit</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="pencil" />
                                    </Button.Content>
                                </Button>
                                <Button
                                    animated="vertical"
                                    color="red"
                                    onClick={() => {
                                        openConfirmDeleteModal(item.itemId);
                                    }}
                                >
                                    <Button.Content visible>Delete</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="trash" />
                                    </Button.Content>
                                </Button>
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>
                );
            });
        } else {
            return (
                <Table.Row key={0}>
                    <Table.Cell colSpan={5} textAlign="center">
                        No Incidents have been logged yet.
                    </Table.Cell>
                </Table.Row>
            );
        }
    };

    return (
        <div>
            { isAdmin && <Header as="h2">All Forms</Header> }
            <Grid columns={3} stackable>
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>Form Type</label>
                            <Dropdown
                                placeholder="Select Form Type"
                                selection
                                options={[
                                    { key: "all", text: "All", value: "all" },
                                    { key: "draft", text: "Draft", value: "draft" },
                                    { key: "completed", text: "Completed", value: "completed" },
                                ]}
                                value={formType ?? 'all'}
                                onChange={handleFormTypeChange}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                {isAdmin && (
                    <Grid.Column>
                        <Form>
                            <Form.Field>
                                <label>Team Leader</label>
                                <Dropdown
                                    placeholder="Select Team Leader"
                                    selection
                                    options={[{ key: "all", text: "All", value: "all" }, ...teamLeaderOptions]}
                                    value={teamLeader ?? 'all'}
                                    onChange={handleTeamLeaderChange}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                )}
                {isAdmin && (
                    <Grid.Column>
                        <Form>
                            <Form.Field>
                                <label>Site</label>
                                <Dropdown
                                    placeholder="Select Site"
                                    selection
                                    options={[{ key: "all", text: "All", value: "all" }, ...safeSpaceOptions]}
                                    value={site ?? 'all'}
                                    onChange={handleSiteChange}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                )}
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>Start Date</label>
                            <Input
                                type="date"
                                value={startDate ?? dateRange.startDate}
                                onChange={(e) => {
                                    const newStartDate = e.target.value;
                                    setDateRange((prevRange) => ({
                                        ...prevRange,
                                        startDate: newStartDate,
                                    }));
                                    dispatch(updatePaginate({startDate: newStartDate}));
                                    if (newStartDate && DateTime.fromISO(newStartDate) <= DateTime.fromISO(dateRange.endDate)) {
                                        setIsValidDateRange(true);
                                    } else {
                                        setIsValidDateRange(false);
                                    }
                                }}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>End Date</label>
                            <Input
                                type="date"
                                value={endDate ?? dateRange.endDate}
                                onChange={(e) => {
                                    const newEndDate = e.target.value;
                                    setDateRange((prevRange) => ({
                                        ...prevRange,
                                        endDate: newEndDate,
                                    }));
                                    dispatch(updatePaginate({endDate: newEndDate}));
                                    if (dateRange.startDate && DateTime.fromISO(dateRange.startDate) <= DateTime.fromISO(newEndDate)) {
                                        setIsValidDateRange(true);
                                    } else {
                                        setIsValidDateRange(false);
                                    }
                                }}
                            />
                        </Form.Field>
                    </Form>
                    {!isValidDateRange && <p style={{ color: 'red' }}>Please select a valid date range.</p>}
                </Grid.Column>
            </Grid>
            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Start Date/Time</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Site</Table.HeaderCell>
                        <Table.HeaderCell>Clients</Table.HeaderCell>
                        {isAdmin && <Table.HeaderCell>Team Leader</Table.HeaderCell>}
                        <Table.HeaderCell width={4} />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {loading ? (
                        <Table.Row>
                            <Table.Cell colSpan={5} textAlign="center" verticalAlign="middle">
                                <Loader active inline content="Loading..." />
                            </Table.Cell>
                        </Table.Row>
                    ) : (
                        generateDataRows()
                    )}
                </Table.Body>
                {formState.totalCount > 0 && (
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={5}>
                                <Grid stackable>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <Form>
                                                <Form.Field>
                                                    <label>Show</label>
                                                    <Dropdown
                                                        selection
                                                        options={[
                                                            { key: "10", text: "10", value: 10 },
                                                            { key: "25", text: "25", value: 25 },
                                                            { key: "50", text: "50", value: 50 },
                                                        ]}
                                                        value={pageSize ?? 10}
                                                        onChange={handlePageSizeChange}
                                                    />
                                                </Form.Field>
                                            </Form>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form>
                                                <Form.Field>
                                                    <label>Go to Page</label>
                                                    <Form.Input
                                                        type="number"
                                                        min={1}
                                                        max={Math.ceil((formState.totalCount)/ (pageSize ?? 10))}
                                                        value={pageIndex}
                                                        onChange={handlePageIndexChange}
                                                    />
                                                </Form.Field>
                                            </Form>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            <Menu pagination>
                                                <Menu.Item
                                                    as="a"
                                                    icon
                                                    disabled={pageIndex === 1}
                                                    onClick={() => dispatch(updatePaginate({ pageIndex: pageIndex - 1 }))}
                                                >
                                                    <Icon name="chevron left" />
                                                </Menu.Item>
                                                <Menu.Item>
                                                    Page {pageIndex} of {Math.ceil(formState.totalCount / (pageSize ?? 10))}
                                                </Menu.Item>
                                                <Menu.Item
                                                    as="a"
                                                    icon
                                                    disabled={pageIndex === Math.ceil(formState.totalCount / (pageSize ?? 10))}
                                                    onClick={() => dispatch(updatePaginate({ pageIndex: pageIndex + 1 }))}
                                                >
                                                    <Icon name="chevron right" />
                                                </Menu.Item>
                                            </Menu>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                )}

            </Table>

            <ConfirmDeleteModal
                modalOpen={formState.confirmDeleteModalOpen}
                incidentFormToDelete={formState.confirmDeleteFormId}
                closeConfirmDeleteModal={closeModal}
                rerouteToDraftFormsFlag={false}
                setRefresh={setRefresh}
            />
        </div>
    );
};

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile,
    };
};
export default connect(mapStateToProps)(AllForms);
